@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/spacing-base';

.vacancy-conversion-info {
    display: flex;
    flex-direction: column;
    gap: 6 * @spacing-base;
    padding: 5 * @spacing-base;
    border: 1px solid @color-gray-20;
    border-radius: 3 * @rounded-corner-default;
}

.columns {
    display: flex;
    flex-wrap: wrap;
    gap: 2 * @spacing-base 1.5 * @spacing-base;
}

.column-box {
    display: flex;
    flex-direction: column;
    gap: @spacing-base;
    flex: 1 1 auto;
}

.value_color {
    color: @color-special-absolute-black;
}
