@import '~bloko/common/styles/media/_screen';

.auction-replenishment-content {
    @media @screen-gt-xs {
        min-width: 376px;
    }
}

.auction-replenishment__control-error {
    min-height: 12px;
    padding-top: 4px;
    box-sizing: border-box;
}

.auction-replenishment__budget-chip {
    cursor: pointer;
}
