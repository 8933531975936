@import '@hh.ru/magritte-design-tokens/base-variables';

.quality-low {
    color: @magritte-color-base-red-60;
}

.quality-mid {
    color: @magritte-color-base-orange-70;
}

.quality-high {
    color: @magritte-color-base-green-60;
}

.quality-no-calc {
    color: @magritte-color-base-graphite-20;
}
