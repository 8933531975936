@import '@hh.ru/magritte-design-tokens/base-variables';
@import '@hh.ru/magritte-design-tokens/tokens';

.ticks-x-axis {
    .magritte-typography-label-2-regular();

    fill: @magritte-color-text-secondary;

    text-anchor: middle;
}
