@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/text-base';

.text {
    .bloko-text-medium();
}

.text_account-low-balance {
    color: @color-red-60;
}

.text_active {
    color: @color-green-60;
}

.text_blocked,
.text_campaign-low-balance {
    color: @color-orange-70;
}
