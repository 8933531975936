@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

@v-spacing-6-count: 2;
@leading-rate-height: 64px;
@input-height: 116px;

.content-wrapper {
    @media @screen-gt-xs {
        width: 640px;
    }
}

.centering-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: @spacing-base * 6 * @v-spacing-6-count + @leading-rate-height + @input-height;
}

.modal-status {
    margin-top: 8px;

    @media @screen-gt-xs {
        margin-left: auto;
        margin-top: 0;
    }
}

.modal-footer-inner {
    display: flex;
    flex-wrap: wrap;

    @media @screen-gt-xs {
        flex-wrap: nowrap;
    }
}

.modal-footer-button-container {
    display: flex;
    margin-left: auto;

    @media @screen-gt-xs {
        display: block;
    }
}

.modal-header-inner {
    display: flex;
    flex-direction: column;

    @media @screen-gt-xs {
        align-items: center;
        flex-direction: row;
    }
}

.modal-footer-button-wrapper {
    margin-bottom: 12px;
    margin-left: auto;
    width: 100%;

    @media @screen-gt-xs {
        margin-bottom: 0;
        margin-left: 0;
        width: auto;
    }
}

.draft-action {
    background-color: @color-gray-20;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 20px;
}
