@import '@hh.ru/magritte-design-tokens/base-variables';

.filter {
    filter: blur(12px);
    opacity: 0.4;
}

.chart-background {
    fill: @magritte-color-base-white;
    fill-opacity: 0.6;
}

.range-color {
    fill: @magritte-color-base-graphite-90;
}
