@import '@hh.ru/magritte-design-tokens/base-variables';

.axis-tick {
    .magritte-typography-label-3-regular();

    text-anchor: middle;
    fill: @magritte-color-base-graphite-55;
}

.axis-tick:first-child {
    text-anchor: start;
}

.day-off {
    fill: @magritte-color-base-red-75;
}
