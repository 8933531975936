@import '~bloko/common/styles/text-base';

.banner {
    color: @color-gray-80;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.banner-header {
    .bloko-text-strong();

    color: @color-gray-80;
}
.banner-content {
    flex-grow: 1;
}
.banner-icon {
    flex-shrink: 1;
    margin-top: 3px;
}
