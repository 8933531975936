@import '@hh.ru/magritte-design-tokens/base-variables';

.loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
}

.loading-error {
    display: flex;
    align-items: center;
    gap: @magritte-core-space-2-x;
}
