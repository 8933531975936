@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-design-tokens/base-variables';

.card {
    display: flex;
    flex-direction: column;
    flex: 1 1 300px;
    border-radius: @magritte-core-space-6-x;
    border: 1px solid @magritte-color-base-graphite-90;
}

.card-content {
    display: flex;
    flex-direction: column;
    padding: @magritte-core-space-6-x;
    gap: @magritte-core-space-6-x;
}

.card-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-title {
    display: flex;
    align-items: center;
    gap: @magritte-core-space-3-x;
}

.card-title-icon-right {
    display: flex;
    justify-content: flex-end;
}

.card-clickable {
    cursor: pointer;
    transition: box-shadow 0.2s;
}

.card-clickable:hover {
    border-color: @magritte-color-base-graphite-85;
    box-shadow: @magritte-shadow-level-1-x @magritte-shadow-level-1-x @magritte-shadow-level-1-blur
        @magritte-shadow-level-1-spread @magritte-shadow-level-1-color;
}
