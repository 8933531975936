@import '~bloko/common/styles/spacing-base';

.vacancy-action-layout {
    display: flex;
    align-items: center;
    gap: @spacing-base * 2;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.vacancy-action-layout-menu-item {
    height: @spacing-base * 10;
}
