@import '~bloko/common/styles/media/_screen';

.modal-footer-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;
}

.modal-control-error {
    padding-top: 4px;
}

.modal-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    @media @screen-gt-xs {
        min-width: 866px;
        min-height: 355px;
    }
}

.modal-actions {
    padding-left: 36px;
    margin-left: auto;
}

.modal-content-wrapper {
    display: flex;
    align-items: flex-start;
    gap: 24px;

    @media @screen-lt-m {
        align-items: center;
        flex-direction: column;
    }
}

.modal-content-statistics {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    width: 340px;

    @media @screen-lt-m {
        width: 605px;
    }

    @media @screen-lt-s {
        width: 100%;
    }
}
