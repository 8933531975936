@import '@hh.ru/magritte-design-tokens/base-variables';

.tooltip-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tooltip-actions {
    margin-top: @magritte-core-space-4-x;
}
