@import '~bloko/common/styles/colors';

.price-product-text-wrapper {
    align-self: start;
    text-align: left;
}

.price-product-money {
    min-width: 100px;
    text-align: right;
    padding-bottom: 1px;
}

.divider {
    background-color: @color-gray-20;
    height: 1px;
}
