@import '@hh.ru/magritte-design-tokens/base-variables';

.publication-legend {
    display: flex;
    gap: @magritte-core-space-4-x;
    flex-wrap: wrap;
}

.legend-item {
    display: flex;
    justify-content: space-between;
    gap: @magritte-core-space-2-x;
    align-items: center;
}

.legend-item-icon {
    width: 28px;
    height: 8px;
    border-radius: @magritte-core-space-2-x;
}

.legend-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    text-align: center;
}

.prolongation-icon {
    background-color: @magritte-color-base-violet-45;
    .legend-icon();

    > svg {
        vertical-align: middle;
    }
}
