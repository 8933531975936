@import '@hh.ru/magritte-design-tokens/base-variables';

.chart-background {
    border-radius: 30px;
    background-color: @magritte-color-base-graphite-96;
    line-height: 0;
    padding: @magritte-core-space-2-x;
    overflow: hidden;
}

.chart-ticks-wrapper {
    padding: 0 @magritte-core-space-2-x;
    line-height: 0;
}
