@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';

.root {
    padding: @spacing-base*5;
    border: 1px solid @color-gray-20;
    border-radius: @spacing-base*3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.content {
    width: 100%;

    @media @screen-lt-m {
        margin-bottom: @spacing-base*8;
    }
}

.status {
    border-bottom: 1px solid @color-gray-20;
}

.conversion {
    display: flex;
    align-items: center;
    gap: @spacing-base;
}
