@import '@hh.ru/magritte-ui/tokens';

.legend-point {
    height: 8px;
    width: 8px;
    border-radius: 50%;
}

.legend-point-green {
    background: @magritte-color-base-green-75;
}

.legend-point-blue {
    background: @magritte-color-base-blue-60;
}
