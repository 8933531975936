@import '@hh.ru/magritte-design-tokens/base-variables';

.line-chart-point-text {
    &:extend(.line-chart_ticks);

    transform: translateY(-8px);
}

.line-chart_ticks {
    .magritte-typography-label-3-regular();

    text-anchor: middle;
    pointer-events: none;

    g {
        font-size: inherit;
    }
}
