@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.budget-banner {
    padding: 20px;
    border: 1px solid @color-gray-20;
    border-radius: 12px;
}

.budget__balance {
    color: @color-gray-80;
    font-size: inherit;

    @media @screen-gt-xs {
        /* stylelint-disable declaration-property-value-allowed-list */
        font-size: 24px;
    }
}

.budget__status-hint {
    color: @color-orange-70;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}
