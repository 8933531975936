@import '@hh.ru/magritte-design-tokens/base-variables';

.chart-legend-info {
    display: flex;
    align-items: center;
    gap: @magritte-core-space-3-x;
}

.chart-legend-info-circle {
    width: @magritte-core-space-3-x;
    height: @magritte-core-space-3-x;
    border-radius: @magritte-core-space-6-x;
}
