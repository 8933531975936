@import '@hh.ru/magritte-design-tokens/base-variables';

.metric-column-info-content {
    display: flex;
    align-items: center;
    gap: @magritte-core-space-2-x;
}

.metric-wrap {
    display: flex;
    justify-content: space-between;
}
