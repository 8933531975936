@import '@hh.ru/magritte-design-tokens/base-variables';

.chart {
    display: flex;
    flex-direction: column;
    padding: @magritte-core-space-8-x @magritte-core-space-6-x @magritte-core-space-6-x;
    border-radius: @magritte-core-space-6-x;
    border: 1px solid @magritte-color-base-graphite-90;
    gap: @magritte-core-space-6-x;
}

.chart-legend-wrap {
    display: flex;
    justify-content: space-between;
}
